import React, { useState, useEffect } from "react";

// components
import Bold from "../../../components/Typography/Bold";
import Flex from "../../../components/Containers/Flex";
import PrivateRoute from "../../../components/Authentication/PrivateRoute";
import LoadingError from "../../../components/Loaders/LoadingError";
import {
  SpacedPaper,
  SectionHeader,
} from "../../../components/Containers/SpacedPaper";
import { receiverAPI, labAPI } from "../../../utils/api/v2";
import ReceiverData from "../../../components/Laboratory/ReceiverData";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CardContainer from "../../../components/Containers/CardContainer";

interface ReceiverProfileProps {
  id: string;
}

const ReceiverProfile = (props: ReceiverProfileProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [receiverData, setReceiverData] = useState<any>({});
  const [labs, setLabs] = useState<any[]>([]);
  const [receiverLabs, setReceiverLabs] = useState<any[]>([]);

  const fetchData = async (): Promise<void> => {
    setLoading(true);
    try {
      const reqs = await Promise.all([
        receiverAPI.get(props.id),
        receiverAPI.listLabs(props.id),
      ]);
      const labsReq = await labAPI.list({
        country: reqs[0].data.country,
        with_items: true,
      });
      setReceiverData(reqs[0].data);
      setReceiverLabs(reqs[1].data);
      setLabs(labsReq.data);
    } catch (err) {
      console.log(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError loading={loading} error={error} />
      {!loading && (
        <>
          <ReceiverData receiverData={receiverData} />
          <SpacedPaper variant="outlined">
            <Flex direction="column">
              <SectionHeader>
                <Bold>Laboratorios</Bold>
              </SectionHeader>
              <CardContainer
                padding="1rem"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {labs.map((lab) => (
                  <Card variant="outlined" style={{ width: "18rem" }}>
                    <CardContent>
                      <Flex justify="space-between" margin="0 0 2rem 0">
                        <FormControlLabel
                          control={
                            <Switch
                              name="active"
                              color="primary"
                              checked={receiverLabs.some(
                                (rl) => rl.id === lab.id
                              )}
                              onChange={async () => {
                                if (
                                  receiverLabs.some((rl) => rl.id === lab.id)
                                ) {
                                  const newReceiverLabs =
                                    await receiverAPI.removeLabs(props.id, [
                                      lab.id,
                                    ]);
                                  setReceiverLabs(newReceiverLabs.data);
                                } else {
                                  const newReceiverLabs =
                                    await receiverAPI.addLabs(props.id, [
                                      lab.id,
                                    ]);
                                  setReceiverLabs(newReceiverLabs.data);
                                }
                              }}
                            />
                          }
                          label={
                            receiverLabs.some((rl) => rl.id === lab.id)
                              ? "Asignado"
                              : "Inasignado"
                          }
                        />
                      </Flex>
                      <Typography gutterBottom variant="h6" component="h3">
                        {lab.display_name}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </CardContainer>
            </Flex>
          </SpacedPaper>
        </>
      )}
    </PrivateRoute>
  );
};

export default ReceiverProfile;
