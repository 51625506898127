import React, { useState } from "react";
import styled from "styled-components";

// typings

// components
import { SpacedPaper, SectionHeader } from "../Containers/SpacedPaper";
import Flex from "../Containers/Flex";
import Bold from "../Typography/Bold";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Link } from "gatsby";

const LaboratoryDataContainer = styled.div`
  display: grid;
  grid-template-columns: 6rem 1fr;
  grid-template-rows: 1fr 1fr;
`;

interface Props {
  receiverData: any;
}

const ReceiverData = (props: Props): JSX.Element => {
  return (
    <SpacedPaper variant="outlined">
      <Flex direction="column">
        <SectionHeader>
          <Bold>Información del laboratorio</Bold>
          <ButtonGroup variant="text">
            <Link
              color="primary"
              to={`/receiver/${props.receiverData.id}/edit`}
            >
              Editar
            </Link>
          </ButtonGroup>
        </SectionHeader>
        <hr />
        <Flex padding="2rem" justify="flex-start" gap="10rem">
          <LaboratoryDataContainer>
            <Bold>Nombre: </Bold>
            {props.receiverData.name}
          </LaboratoryDataContainer>
          <LaboratoryDataContainer>
            <Bold>País: </Bold>
            {props.receiverData.country}
          </LaboratoryDataContainer>
          <LaboratoryDataContainer>
            <Bold>Timezone: </Bold>
            {props.receiverData.timezone}
          </LaboratoryDataContainer>
        </Flex>
      </Flex>
    </SpacedPaper>
  );
};

export default ReceiverData;
